import React from "react";
import {Navbar, Nav, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./footer.css"
import {FaLinkedin, FaGithub, FaTwitterSquare} from 'react-icons/fa';
import { IoIosMailOpen } from "react-icons/io";
import { GoLinkExternal } from "react-icons/go";

function Footer() {
    return (
      <div>
        <Row>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="footerlaptop" className="footer-nav-bar-laptop border-top border-dark" fixed="bottom">
            <span>
              Raywon Kari © {new Date().getFullYear()}<br/>
              Deployed on August 17, 2020<br/>
              Built with {` `} <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React <GoLinkExternal/></a> & {` `} <a href="https://www.gatsbyjs.org" target="_blank" rel="noopener noreferrer">Gatsby <GoLinkExternal/></a>
            </span>
            <Nav className="justify-content-center ml-auto footer-navs-laptop">
              <Nav.Link className="footer-nav-link-laptop" href="mailto:its@raywonkari.com?Subject=For some reason I ended up on your website, and I want to talk to you..."><IoIosMailOpen size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-laptop" href="https://linkedin.com/in/raywonkari/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-laptop" href="https://github.com/raywonkari/" target="_blank" rel="noopener noreferrer"><FaGithub size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-laptop" href="https://twitter.com/raywonkari/" target="_blank" rel="noopener noreferrer"><FaTwitterSquare size={40}/></Nav.Link>
            </Nav>
          </Navbar>

          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="footertablet" className="footer-nav-bar-tablet border-top border-dark text-center" fixed="bottom">
            <span>
              Raywon Kari © {new Date().getFullYear()}<br/>
              Deployed on August 17, 2020<br/>
              Built with {` `} <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React <GoLinkExternal/></a> & {` `} <a href="https://www.gatsbyjs.org" target="_blank" rel="noopener noreferrer">Gatsby <GoLinkExternal/></a>
            </span>
            <Nav className="footer-navs-tablet">
              <Nav.Link className="footer-nav-link-laptop" href="mailto:its@raywonkari.com?Subject=For some reason I ended up on your website, and I want to talk to you..."><IoIosMailOpen size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-tablet" href="https://linkedin.com/in/raywonkari/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-tablet" href="https://github.com/raywonkari/" target="_blank" rel="noopener noreferrer"><FaGithub size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-tablet" href="https://twitter.com/raywonkari/" target="_blank" rel="noopener noreferrer"><FaTwitterSquare size={40}/></Nav.Link>
            </Nav>
          </Navbar>

         <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="footermobile" className="footer-nav-bar-mobile border-top border-dark text-center" fixed="bottom">
            <span>
              Raywon Kari © {new Date().getFullYear()}<br/>
              Deployed on August 17, 2020<br/>
              Built with {` `} <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React <GoLinkExternal/></a> & {` `} <a href="https://www.gatsbyjs.org" target="_blank" rel="noopener noreferrer">Gatsby <GoLinkExternal/></a>
            </span>
            <Nav className="footer-navs-mobile">
              <Nav.Link className="footer-nav-link-laptop" href="mailto:its@raywonkari.com?Subject=For some reason I ended up on your website, and I want to talk to you..."><IoIosMailOpen size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-mobile" href="https://linkedin.com/in/raywonkari/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-mobile" href="https://github.com/raywonkari/" target="_blank" rel="noopener noreferrer"><FaGithub size={40}/></Nav.Link>
              <Nav.Link className="footer-nav-link-mobile" href="https://twitter.com/raywonkari/" target="_blank" rel="noopener noreferrer"><FaTwitterSquare size={40}/></Nav.Link>
            </Nav>
          </Navbar>
        </Row>
      </div>
    );
  }
  
  export default Footer;