import React from "react";
import {Nav, Navbar, Row, Dropdown, DropdownButton} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./header.css"
import { FiMenu } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";

function Header() {

  return(
      <div>
        <Row>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="headerlaptop" className="header-nav-bar-laptop border-bottom border-dark" fixed="top">
            <Navbar.Brand href="/" className="header-brand-name-laptop">Raywon Kari</Navbar.Brand>
            <Nav className="justify-content-center ml-auto header-navs-laptop">
              <Nav.Link className="header-nav-link-laptop" href="/about">About</Nav.Link>
              <Nav.Link className="header-nav-link-laptop" href="/certs">Certifications</Nav.Link>
              <Nav.Link className="header-nav-link-laptop" href="/timeline">Timeline</Nav.Link>
              <Nav.Link className="header-nav-link-laptop" href="/contact">Contact</Nav.Link>
              <Nav.Link className="header-nav-link-laptop" href="https://raywontalks.com" target="_blank">Blog <GoLinkExternal/></Nav.Link>
            </Nav>
          </Navbar>

          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="headertablet" className="header-nav-bar-tablet border-bottom border-dark" fixed="top">
            <Navbar.Brand href="/" className="header-brand-name-tablet">Raywon Kari</Navbar.Brand>
            <DropdownButton alignRight variant='secondary' title={<FiMenu size={30}/>} className="header-dropdown-tablet">
              <Dropdown.Item href="/about">About</Dropdown.Item>
              <Dropdown.Item href="/certs">Certifications</Dropdown.Item>
              <Dropdown.Item href="/timeline">Timeline</Dropdown.Item>
              <Dropdown.Item href="/contact">Contact</Dropdown.Item>
              <Dropdown.Item href="https://raywontalks.com" target="_blank">Blog <GoLinkExternal/></Dropdown.Item>
            </DropdownButton>
          </Navbar>

          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="headermobile" className="header-nav-bar-mobile border-bottom border-dark" fixed="top">
            <Navbar.Brand href="/" className="header-brand-name-mobile">Raywon Kari</Navbar.Brand>
            <DropdownButton alignRight variant='secondary' title={<FiMenu size={30}/>} className="header-dropdown-mobile">
              <Dropdown.Item href="/about">About</Dropdown.Item>
              <Dropdown.Item href="/certs">Certifications</Dropdown.Item>
              <Dropdown.Item href="/timeline">Timeline</Dropdown.Item>
              <Dropdown.Item href="/contact">Contact</Dropdown.Item>
              <Dropdown.Item href="https://raywontalks.com" target="_blank">Blog <GoLinkExternal/></Dropdown.Item>
            </DropdownButton>
          </Navbar>
          </Row>
      </div>
  );
}

export default Header;